import React from "react";
import "./index.css";

import BrandLogos from './BrandLogos';

import Spring from '../AnimationScenes/Spring';

const Resume = ({ inFocus }) => {
    return (
        <div
            className={"app-page-container " + (inFocus ? "clip-animate" : "")}
            style={{ zIndex: inFocus ? 0 : -1 }}
        >
            <div className="app-section resume-green clip-section-bottom-to-top">
                {(inFocus)?<Spring/>:""}
                <div className="resume-name resume-name-first clip-primary-bottom-to-top">
                    ALMA MATER
                </div>
                {/* spring scene */}
                {/* internet explorer fix */}
                <div  style={{ zIndex: inFocus ? 1 : 'auto' }} className="resume-section resume-section-institute clip-secondary-right-to-left">
                    <div className="resume-institute resume-institute-full">
                        <h3>B.E in Computer Science and Engineering</h3>
                        <div>Model Institute of Engineering and Technology</div>
                        <div><i>Jammu and Kashmir, India</i></div>
                        <div>Graduated 2018</div>
                    </div>
                    <div className="resume-institute resume-institute-full">
                        <h3>M.Tech in Computer Science and Engineering</h3>
                        <div>Shri Mata Vaishno Devi University</div>
                        <div><i>Jammu and Kashmir, India</i></div>
                        <div>Graduated 2021</div>
                    </div>
                    <div className="resume-institute resume-institute-min">
                        <h3>B.E in CSE</h3>
                        <div>MIET, JK, IN</div>
                        <div>Graduated 2018</div>
                    </div>
                    <div className="resume-institute resume-institute-min">
                        <h3>M.Tech in CSE</h3>
                        <div>SMVDU, JK, IN</div>
                        <div>Graduated 2021</div>
                    </div>
                </div>
            </div>
            <div className="app-section resume-black clip-section-top-to-bottom">
                <div className="resume-name clip-primary-bottom-to-top resume-name-last">
                    SYNOPSIS
                </div>
                <div className="resume-section resume-section-exp clip-secondary-left-to-right">
                    <BrandLogos fillHexColor={"#b2ff59"}/>
                    <a className="resume-download-button" href="/suhail_gulzar_mern_data_sci.pdf"  target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-file-download"></i>download resume
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Resume;
