import React from "react";

const ExternalLinks = () => {
    return (
        <React.Fragment>
            <a href="https://github.com/gulzarsuhail" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github-square" />
            </a>
            <a href="https://www.linkedin.com/in/suhail-gulzar-188719b2" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin" />
            </a>

            <a href="mailto:contact@suhailgulzar.com">
                <i className="fas fa-envelope-square" />
            </a>
        </React.Fragment>
    );
};

export default ExternalLinks;
