class Swyper {

    constructor(element) {
        this.x = null;
        this.y = null;
        this.element =  element;

        this.element.addEventListener('touchstart', function(e) {
            this.x = e.touches[0].clientX;
            this.y = e.touches[0].clientY;
        }.bind(this), false);
    }

    onLeft (cb) {
        if (cb) this.onLeft = cb;
        return this;
    }

    onRight (cb) {
        if (cb) this.onRight = cb;
        return this;
    }

    onUp (cb) {
        if (cb) this.onUp = cb;
        return this;
    }

    onDown (cb) {
        if (cb) this.onDown = cb;
        return this;
    }

    handleTouchMove = e => {
        if ( ! this.x || ! this.y ) {
            return;
        }

        let xUp = e.touches[0].clientX;
        let yUp = e.touches[0].clientY;

        this.xDiff = this.x - xUp;
        this.yDiff = this.y - yUp;

        if ( Math.abs( this.xDiff ) > Math.abs( this.yDiff ) ) { 
            if ( this.xDiff > 0 ) {
                this.onLeft();
            } else {
                this.onRight();
            }
        } else {
            if ( this.yDiff > 0 ) {
                this.onUp();
            } else {
                this.onDown();
            }
        }

        this.x = null;
        this.y = null;
    }

    run() {
        this.element.addEventListener('touchmove', 
            this.handleTouchMove.bind(this)
        );
    }
}

export default Swyper;