import React from "react";

import './index.css';

import SnowFlake from '../../../images/snowflake.svg'

const Winter = () =>  {


    return (
        <div id="winter">
            <img src={SnowFlake} className="winter-snowflake" height="150%" alt="winter" />
        </div>
    );
}

export default Winter;