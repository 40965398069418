import React from 'react';

const BlackPage = ({inFocus}) => {

    const style = {        
        zIndex: (inFocus) ? 0 : -1,
    }

    return (
        <div className="react-loading" style={style}>
            <span className="react-loading-text">LOADING</span>
            <span className="react-loading-text">LOADING</span>
        </div>
    )
}

export default BlackPage;