import React from 'react';
import anime from 'animejs/lib/anime.es.js';

import './index.css';

class Spring extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            horizontalCloves: 0,
            verticalCloves: 0,
        }
        this.cloveSize = 0;
        this.springRef = React.createRef();
        this.cloverFillColor = "#aaf553";
        this.changeCounter = 0;
        this.screenSizeChangeHandler = this.screenSizeChangeHandler.bind(this);
    }

    animateCloves(cloveClass){
        const direction = ((cloveClass === "clove-leaf-right") ? 1 : -1)
        const rotateAngle = 360 * direction;
        const translateX = this.cloveSize * direction;
        anime({
            targets: "." + cloveClass,
            rotateZ: {value: rotateAngle, duration: 1500, easing: "cubicBezier(0.34, 1.56, 0.64, 1)", delay: 200},
            translateX: {value: translateX, duration: 2000, delay: 1500, easing: "cubicBezier(0.33, 1, 0.68, 1)"},
            scale: [
                {value: 0.6, duration: 0},
                {value: 1, duration: 1500},
                {value: 0.6, duration: 2000, easing: "cubicBezier(0.33, 1, 0.68, 1)"}
            ],  
            easing: 'linear',
            loop: true,
        });
    }

    async screenSizeChangeHandler(){
        this.changeCounter++;
        // remove old animations
        anime.remove(".clove-leaf-right");
        anime.remove(".clove-leaf-left");

        const sceneHeight = this.springRef.current.clientHeight;
        const sceneWidth = this.springRef.current.clientWidth;
        
        this.setState ({
            horizontalCloves: Math.ceil(sceneWidth/110) + 2,
            verticalCloves: Math.ceil(sceneHeight/100),
            cloveSize: 100,
        });
    }


    componentDidUpdate(){

        const cloveLine = document.getElementsByClassName("clove-leaf-right");
        this.cloveSize = cloveLine[1].getBoundingClientRect().x - cloveLine[0].getBoundingClientRect().x;

        this.animateCloves("clove-leaf-right");
        this.animateCloves("clove-leaf-left");
    }

    componentDidMount(){
        this.screenSizeChangeHandler();
        window.addEventListener('resize', this.screenSizeChangeHandler);
    }

    
    componentWillUnmount() {
        window.removeEventListener('resize', this.screenSizeChangeHandler);
    }

    renderNewClover(id, cloveClass){
        return (
            <svg
                className={"spring-leaf "+cloveClass}
                key={id}
                xmlns="http://www.w3.org/2000/svg"
                width={this.state.cloveSize}
                viewBox="0 0 113.57536 113.51262">
                <path
                    d="m 30.883772,27.781416 25.903902,26.10392 26.136771,-26.30751 c 3.098272,-4.77473 4.253188,-10.68468 2.603016,-15.30456 C 81.513656,1.0361057 68.158148,-4.6666243 57.067568,4.4540857 50.545527,-0.26750435 43.865869,-0.53388435 39.549352,0.95334565 28.23332,4.8522157 23.780099,16.469116 30.883772,27.781416 Z"
                    fill={this.cloverFillColor}
                />
                <path
                    d="m 30.883773,85.645526 25.903901,-26.10394 26.136771,26.30752 c 3.098278,4.77473 4.253195,10.68468 2.603016,15.304564 -4.013793,11.23716 -17.369307,16.93988 -28.459884,7.81918 -6.522037,4.72159 -13.201699,4.98797 -17.518219,3.50074 C 28.233325,108.57472 23.780101,96.957806 30.883773,85.645526 Z"
                    fill={this.cloverFillColor}
                />
                <path
                    d="m 27.85562,30.809566 26.103932,25.9039 -26.307516,26.13678 c -4.77473,3.09827 -10.68468,4.25319 -15.304562,2.60301 -11.2371559,-4.0138 -16.9398866,-17.36931 -7.8191829,-28.45988 -4.72158161,-6.52204 -4.98796771,-13.2017 -3.5007371,-17.51822 3.8988687,-11.31604 15.515779,-15.76926 26.828066,-8.66559 z"
                    fill={this.cloverFillColor}
                />
                <path
                    d="m 85.719726,30.809566 -26.103923,25.9039 26.30751,26.13678 c 4.774735,3.09827 10.684682,4.25319 15.304567,2.60301 11.23715,-4.0138 16.93989,-17.36931 7.81918,-28.45989 4.72158,-6.52204 4.98796,-13.2017 3.50073,-17.51821 -3.89886,-11.31604 -15.515773,-15.76926 -26.828064,-8.66559 z"
                    fill={this.cloverFillColor}
                />
            </svg>
        )
    }

    render() {

        const springLeaves = [];
        for (let i=0; i<this.state.verticalCloves; i++){
            const springLine = [];
            for (let j=0; j<this.state.horizontalCloves; j++){
                springLine.push(this.renderNewClover( 100*this.changeCounter+10*i+j, (i%2===0)?"clove-leaf-right":"clove-leaf-left"));
            }
            springLeaves.push(
                <div key={"line"+i} className="spring-line">
                    {springLine}
                </div>
            )
        }

        return (
            <div id="spring" ref={this.springRef}>
                {springLeaves}
            </div>
        )
    }
}

export default Spring;