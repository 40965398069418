import React from "react";
import "./NavBar.css";

import { NavLink } from "react-router-dom";

class NavBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            screenHeight: document.body.clientHeight,
        }
    }

    screenResize = () => {
        const tempState = {
            screenHeight: document.body.clientHeight,
        }

        this.setState (tempState);
    }

    componentDidMount() {
        window.addEventListener('resize', this.screenResize)
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.screenResize)
    }

    render() {
        const pageLinks = this.props.pages.map((page, index) => (
            <NavLink 
                to={ process.env.PUBLIC_URL + page.path}
                activeClassName="nav-item-active"
                key={page.name}
                exact
            >
                {page.name}
            </NavLink>
        ));

        return (
            <div className="nav-container" style={{width: (this.state.screenHeight + "px")}}>
                {pageLinks}
            </div>
        );
    };
}
export default NavBar;
