import React from "react";
import "./index.css";

import ExternalLinks from "../ExternalLinks";
import ContactForm from "./ContactForm";

import Summer from '../AnimationScenes/Summer';

const Contact = ({ inFocus }) => {
    return (
        <div
            className={"app-page-container " + (inFocus ? "clip-animate" : "")}
            style={{ zIndex: inFocus ? 0 : -1 }}
        >
            {/* upper section of page*/}
            <div className="app-section contact-black clip-section-bottom-to-top">
                <div className="contact-form-container">
                    <ContactForm animation="clip-secondary-right-to-left" />
                </div>
            </div>

            {/* lower section of page */}
            <div className="app-section contact-yellow clip-section-top-to-bottom">
                
                {/* the summer animation */}
                
                {(inFocus)? <Summer/>: ""}
                <div className="contact-head clip-primary-bottom-to-top">
                    Get In Touch
                </div>

                {/* contact details */}
                <div className="contact-channels clip-secondary-left-to-right">
                    <p>
                        <a href="mailto:contact@suhailgulzar.com">
                            contact@suhailgulzar.com
                        </a>
                    </p>
                    <div>
                        <ExternalLinks />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
