import React, { Component } from "react";

import {
    Switch,
    Route,
    withRouter 
} from "react-router-dom";


import "./App.css";
import "./GlobalClipAnimations.css";

import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import BlankPage from './components/BlankPage';

import Swyper from "./Swyper";

class App extends Component {
    constructor(props) {
        super(props);

        this.pages = [
            {
                component: Home,
                name: "home",
                path: "/",
            },
            {
                component: Projects,
                name: "projects",
                path: "/projects",
            },
            {
                component: Resume,
                name: "resume",
                path: "/resume",
            },
            {
                component: Contact,
                name: "contact",
                path: "/contact",
            }
        ];

        this.currentPage = -1;
        this.swiper = new Swyper(window);
        
    }
    
    changeViewHandler = pageNum => {
        const newPath = this.pages[pageNum].path;
        this.props.history.push(newPath);
    };
    
    scrollEvent = direction => {
        if (direction === "up") {
            if (this.currentPage !== 0) {
                this.changeViewHandler(this.currentPage - 1);
            } 
        }
        if (direction === "down") {
            if (this.currentPage !== this.pages.length - 1) {
                this.changeViewHandler(this.currentPage + 1);
            }
        }
    }
    
    wheelHandler = e => {
        window.removeEventListener("wheel", this.wheelHandler)
        
        if (e.deltaY < 0) this.scrollEvent("up");
        else if (e.deltaY > 0) this.scrollEvent("down");
        setTimeout (()=> {
            window.addEventListener("wheel", this.wheelHandler);
        }, 1500);
    }
    
    
    componentDidMount() {
        if("ontouchstart" in window){
            this.swiper.onDown(()=>this.scrollEvent("up"));
            this.swiper.onUp(()=>this.scrollEvent("down"));
            this.swiper.run();
        }
        window.addEventListener('wheel', this.wheelHandler);
    }


    
    render() {
        const BackPage = (this.currentPage === -1) ? BlankPage : this.pages[this.currentPage].component;

        this.currentPage = this.pages.map(e=> e.path).indexOf(this.props.history.location.pathname);

        const switches = this.pages.map(comp => {
            return (
                <Route 
                    path={ process.env.PUBLIC_URL + comp.path }
                    exact key={comp.name}
                >
                    <comp.component inFocus={true} />
                </Route>
            );
        });

        return (
            <React.Fragment>
                
                    <NavBar
                        pages={this.pages}
                        onClickHandler={this.changeViewHandler}
                    />
                    
                    <BackPage inFocus={false} />
                    
                    <Switch>
                        {switches}
                    </Switch>
                    
            
            </React.Fragment>
        );
    }
    
}
    export default withRouter(App);
    