import React, { Component } from "react";
import "./ContactForm.css";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            message: "",
            error: "",
            canSubmit: true,
            success: false
        };
        this.submitButtonRef = React.createRef();
    }

    onInputChangeHandler = event => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value,
        });
    };

    sendMessage = event => {        

        event.preventDefault();

        this.setState({
            ...this.state,
            canSubmit: false
        });

        const data = {
            email: this.state.email,
            message: this.state.message,
        };

        fetch('http://ec2-54-215-191-243.us-west-1.compute.amazonaws.com', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => response.json()).then(data => {
            this.setState({
                email: "",
                message: "",
                canSubmit: true,
                success: true
            });
            setTimeout(()=>this.setState({
                ...this.state,
                success: false
            }), 2000);
        }).catch((error) => {
            this.displayError("Failed to send, please try again.");
            this.setState({
                canSubmit: true,
            })
        });
    }

    displayError(str) {
        this.setState({
            error: str
        });
        // setTimeout(() => this.setState({
        //     error: "",
        // }), 2000);
    }

    render() {
        const animation = this.props.animation;
        return (
            <form id="contact-form" className={animation} onSubmit={this.sendMessage} >
               
                <label htmlFor="email">email</label>
                <input
                    type="email"
                    required
                    maxLength={124}
                    minLength={3}
                    placeholder="your email"
                    name="email"
		    disabled={true}
                    value={this.state.email}
                    onChange={this.onInputChangeHandler}
                    readOnly={!this.state.canSubmit}
                />

                <label htmlFor="message">message</label>
                <textarea
                    rows="3"
                    required
                    maxLength={4096}
                    minLength={3}
                    placeholder="message"
                    name="message"
		    disabled={true}
                    value={this.state.message}
                    onChange={this.onInputChangeHandler}
                    readOnly={!this.state.canSubmit}
                />
                
                <button
                    type="submit"
                    disabled={true}
                    // disabled={!this.state.canSubmit} 
                    ref={this.submitButtonRef}
                    className={ (this.state.error!=="")?"submit-error":(this.state.canSubmit)
                        ? (this.state.success)?"submit-success":""
                        :"submit-busy"
                    }
                >
                    {/* {(this.state.error === "")
                        ?
                            this.state.canSubmit
                                ? this.state.success
                                    ?<i className="fas fa-check-circle"/>
                                    :<i className="fab fa-telegram-plane"/>
                                : <i className="fas fa-circle-notch" id="form-processing"/>
                              
                        :
                        <span>{this.state.error}</span>
                    } */}
                    <span style={{color: "black"}}>Sorry, messaging service is not available right now.</span>
                </button>
            </form>
        );
    }
}

export default ContactForm;
