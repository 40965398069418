import React from "react";
import "./index.css";

import ExternalLinks from '../ExternalLinks';

import Autumn from "../AnimationScenes/Autumn/";

const Home = ({ inFocus }) => {
    return (
        <div
            className={"app-page-container " + (inFocus ? "clip-animate" : "")}
            style={{ zIndex: inFocus ? 0 : -1 }}
        >
            <div className="app-section home-red clip-section-bottom-to-top">
                { inFocus ? <Autumn /> : ""}
                <div className="home-name home-name-first clip-primary-bottom-to-top">
                    Suhail
                </div>
            </div>
            <div className="app-section home-black clip-section-top-to-bottom">
                <div className="home-name home-name-last clip-primary-bottom-to-top">
                    Gulzar
                </div>
                <span className="home-secondary-section clip-secondary-left-to-right">
                    <p>
                        MERN FULL STACK DEVELOPER WITH A DASH OF DATA
                        SCIENCES
                    </p>
                    <div className="home-logos">
                       <ExternalLinks />
                    </div>
                </span>
            </div>
        </div>
    );
};

export default Home;
