export default [
    {
        id: 1,
        name: "endPing",
        description: "Full Stack MERN Project",
        type: "dev",
        summary: "Secure end-to-end encrypted MERN based project with emphasis on confidentiality. The server never has any knowledge of the content sent across by making sure only the end-users have their decryption keys. Email for source code.",
    },
    {
        id: 2,
        name: "VFI SuperScale",
        description: "Research Paper",
        type: "ds",
        summary: "The paper proposes a new method of increasing the spatial resolution of the 3D image successively along all three axes to obtain a super-resolution (SR) 3D image using Video Frame Interpolation. Quantitative analysis of the results demonstrate that this method of 3D spatial enhancement outperforms the previously proposed methods in the majority of the circumstances.",
        paper: "https://link.springer.com/chapter/10.1007/978-981-16-9650-3_35",
    },
    {
        id: 3,
        name: "GNOSIS",
        description: "Full Stack Project",
        type: "dev",
        summary: "This project was aimed at combining in-classroom and out of classroom learning experience by collecting and dispatching educational nuggets to students as per their comprehension level. This project was releases on an institute level and the collected data formed the basis for a research paper. This project was built using node.js, mongoDB, Google FCM and HTML, CSS and JS front end.",
        paper: "https://ieeexplore.ieee.org/abstract/document/8520308",
    },
    {
        id: 4,
        name: "ML Visualized",
        description: "Interactive Visualization",
        type: "ds",
        summary: "An interactive webpage to help visualize the inner working of one of the most popular Machine Learning Algorithms- Linear Regression.",
        git: "https://github.com/gulzarsuhail/ml_playground",
        website: "https://suhailgulzar.com/ml_playground/",
    },
    {
        id: 5,
        name: "Diabetic Retinopathy Detection",
        description: "Internship Project",
        type: "ds",
        summary: "Using images of eye fundus, build and trained a deep learning model capable of classifying an image into one of the stages of Diabetic Retinopathy.",
    },
    {
        id: 6,
        name: "ESP8266 WebServer",
        description: "Side Project",
        type: "dev",
        summary: "An arduino IDE based firmware for nodemcu (ESP8266) that enbles the user to control one to eight relay modules simultaneously through a responsive web interface hosted over the network. This firmware also features an API for getting status of invidual relay modules and switcing them on or off on the fly.",
        git: "https://github.com/gulzarsuhail/ESP8266-IOT-WebServer",
    },
    {
        id: 7,
        name: "Network FW Client",
        description: "Side Project",
        type: "dev",
        summary: "This node app provided a CLI interface to sign into the network firewall in my university. Being platform and OS independent, it worked on systems for which official app or support was not available (e.g Raspberry Pi's). This app would also detect when internet was down and would proceed to relogin.",
        git: "https://github.com/gulzarsuhail/smvdu_firewall_client",
    },
    {
        id: 8,
        name: "Paper Bird",
        description: "Side Project",
        type: "dev",
        summary: "This is a FlappyBirds clone built for the web browser. This game is built mostly using PaperJS animation library coupled with event listners",
        git: "https://github.com/gulzarsuhail/PaperBird",
        website: "https://gulzarsuhail.github.io/PaperBird",
    },
    {
        id: 9,
        name: "SherlockHolmes",
        description: "Minor Project",
        type: "dev",
        summary: "This is a crime solving mystery I developed for a college festival event in nodejs. This node app was created to indulge the participants in a detective role playing game. The role play event included various challanges including decoding cryptic text, filtering through overwhelming amount of data, and others.",
        git: "https://github.com/gulzarsuhail/MIET_SherlockHolmes_Sammailan2K18"
    },
]