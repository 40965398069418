import React from 'react';
import './ProjectCard.css';

const getCardAnimation = (index) => {
    return {
        animation: "project-card-opacity-anim 0.75s "+ (1.1+(index*0.15)) +"s cubic-bezier(0.165, 0.84, 0.44, 1) forwards",
    }
}

const ProjectCard = ({ index, project, animate}) => {

    // check if website link exists
    const webLink = (project.hasOwnProperty("website")) ?
        <a href={project.website}  target="_blank" rel="noopener noreferrer">
            <i className="fas fa-external-link-square-alt"></i>
            website
        </a>
        : "";

    
    // check if github link exists
    const gitLink = (project.hasOwnProperty("git")) ?
        <a href={project.git}  target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i>
            github
        </a>
        : "";


    // check if research paper link exists
    const paperLink = (project.hasOwnProperty("paper")) ?
        <a href={project.paper}  target="_blank" rel="noopener noreferrer">
            <i className="fas fa-book-open"></i>
            paper
        </a>
        : "";

    return(
        <div className="project-card" style={(animate)?getCardAnimation(index):{opacity: 1}}>
            <div className="project-card-intro">
                <h1>{project.name}</h1>
                <p>{project.description}</p>
                <div className="project-card-saperator"></div>
            </div>
            <div className="project-card-data">{project.summary}</div>
            <div className="project-card-locations">
                {webLink}
                {gitLink}
                {paperLink}
            </div>
        </div>
    );
}

export default ProjectCard;

