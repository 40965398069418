import React from 'react';
import anime from 'animejs/lib/anime.es.js';

import './index.css';

import sodacanImage from '../../../images/sodacan.svg';
import sunglassesImage from '../../../images/sunglasses.svg';
import soccerballImage from '../../../images/soccerball.svg';
import flipflopImage from '../../../images/flipflop.svg';
import icecreamImage from '../../../images/icecream.svg';

class Summer extends React.Component {

    constructor(props){
        super(props);
        this.objectImages = [
            sodacanImage, 
            sunglassesImage,
            soccerballImage,
            flipflopImage,
            icecreamImage,
        ];
        this.state = {
            numOfItems: 0,
        }
        this.resizeCount = 0;
        this.summerRef = React.createRef();
        this.screenResizeHandler = this.screenResizeHandler.bind(this);
    }

    componentDidMount() {
        this.screenResizeHandler();
        window.addEventListener('resize', this.screenResizeHandler);
    }

    componentDidUpdate() {
        const animatables = this.summerRef.current.children[0].children;
        this.animateObjects(animatables);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.screenResizeHandler);
    }

    removeAnimations() {
        anime.remove(this.summerRef.current.children[0].children);
    }

    animateObjects(targets) {
       
        for (let i = 0; i< targets.length; i++) {
            targets[i].src = this.objectImages[Math.floor(Math.random()*this.objectImages.length)];
        }

        anime({
            targets: targets,
            opacity: [
                {value: 0, delay: anime.stagger(300)},
                {value: 0.3, duration: 100},
                {value: 0, duration: 2000},
                
            ],
            easing: 'linear',
            complete: this.animateObjects.bind(this, targets),
        })
    }
    
    screenResizeHandler() {
         this.removeAnimations();
        this.setState({
            numOfItems: this.summerRef.current.clientWidth/80,
        });
    }


    render() {
    
        let summerObjects = [];
        for (let i=0; i< this.state.numOfItems; i++){
            summerObjects.push(
                <img 
                    src={this.objectImages[0]}
                    alt="summer objects"
                    height="50"
                    width="50"
                    key={10*this.resizeCount + i}
                />
            );
        }

        return(
            <div id="summer" ref={this.summerRef}>
                <div id="summer-objects">
                    {summerObjects}
                </div>
            </div>
        );
    }

}

export default Summer;