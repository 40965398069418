import React from "react";
import "./index.css";

import ProjectList from '../../ProjectList';
import ProjectCard from './ProjectCard';
import Winter from '../AnimationScenes/Winter'

class Projects extends React.Component  {

    constructor (props){
        super(props);

        this.allProjects = ProjectList;

        this.state = {
            inFocus: props.inFocus,
            active_cat: "all",
            currentCatProjects: [...this.allProjects],
            animateTransCards: true
        };

        this.listScrollerRef = React.createRef();
    }

    setCategory = catName => {
        let tempProjects =  (catName === "all") ? this.allProjects : this.allProjects.filter(e => (e.type === catName));
        this.setState({
            currentCatProjects: tempProjects,
            active_cat: catName,
            animateTransCards: false
        });
    }

    smoothScrollList = direction => {
        const duration = 500;
        let distance = (this.listScrollerRef.current.offsetWidth / 2) * direction;
        let startPosition = this.listScrollerRef.current.scrollLeft;
        let startTime = null;

        let animation = currentTime => {
            if (startTime === null) startTime = currentTime;
            let timeElapsed = currentTime - startTime;
            let run = ease(timeElapsed, startPosition, distance, duration);
            this.listScrollerRef.current.scrollTo(run, 0);
            if (timeElapsed < duration)  requestAnimationFrame(animation);
        }

        let ease = (t, b, c, d) => {
            t /= d/2;
            if (t < 1) return c/2*t*t + b;
            t--;
            return -c/2 * (t*(t-2) - 1) + b;
        }

        requestAnimationFrame(animation);

    }

    render() {

        let projectList = this.state.currentCatProjects.map( project => 
            <ProjectCard 
                key={project.id}
                index={project.id}
                project={project}
                animate={this.state.animateTransCards}
            />
        );

        return (
            <div
                className={"app-page-container " + (this.state.inFocus ? "clip-animate" : "")}
                style={{ zIndex: this.state.inFocus ? 0 : -1 }}
            >
                <div id="projects-intro" className="app-section projects-black clip-section-bottom-to-top">
                    <p className="projects-subtext clip-secondary-right-to-left">
                        Here are a few things I have worked on
                    </p>
                </div>
            

                {/* purple section */}
                <div id="projects-categories" className="app-section projects-purple clip-section-top-to-bottom">
                    
                    {/* the winter animation */}
                    {(this.state.inFocus)? <Winter />: ""} 

                    {/* the filter buttons on bottom of screen */}
                    <span className="clip-secondary-left-to-right">
                        <span className={"project-cat " + ((this.state.active_cat === "all")?"active_cat":"")} onClick={this.setCategory.bind(this, "all")}>all</span>
                        <span className={"project-cat " + ((this.state.active_cat === "dev")?"active_cat":"")} onClick={this.setCategory.bind(this, "dev")}>devel</span>
                        <span className={"project-cat " + ((this.state.active_cat === "ds")?"active_cat":"")} onClick={this.setCategory.bind(this, "ds")}>data sci</span>
                    </span>
                </div>
                <div id="project-list">
                    <div className="project-list-scroller clip-primary-right-to-left" onClick={this.smoothScrollList.bind(this, -1)}>
                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </div>
                    <div id="project-list-container" className="clip-primary-bottom-to-top" ref={this.listScrollerRef}>
                        { projectList }
                        <div className="project-list-pseudo-element"></div>
                    </div>
                    <div className="project-list-scroller clip-primary-left-to-right" onClick={this.smoothScrollList.bind(this, 1)}>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                </div>

                {/* on smaller screens, give the navbar black background */}
                {/* #TODO: Move this to navbar element itself */}
                <div className="project-list-blacktint"></div>

            </div>
        );
    }
};

export default Projects;
